@import '../../../../utils/styles/styles.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: $neutral;
}

.title {
  margin-left: 10px;
}

.userContainer {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 12px;

  > div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
}

.icon {
  transition: transform 0.3s ease;
}

.iconOpen {
  transform: rotate(180deg);
}
