@import '../../../../utils/styles/styles.scss';

.container {
  width: 100%;
  gap: 6px;
  position: relative;
}

.dropdownContainer {
  border-radius: 10px;
  max-height: 2.5vh;
  min-height: 2.5vh;
}

.dropdownButtonStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  height: 2.5vh;
  padding: 10px;
  background-color: $super-clear-gray;
  border-radius: 10px;
  cursor: pointer;

  &.errorForm {
    max-height: 2.5vh;
    border-radius: 12px 12px 0px 0px;
    border-bottom: 2px solid $primary;
  }
}

.dropdownMenuStyle {
  position: absolute;
  margin-top: 5px;
  width: 100%;
  background-color: $super-clear-gray;
  border-radius: 8px;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.08),
    0px 5px 8px 0px rgba(0, 0, 0, 0.06),
    0px 1px 14px 0px rgba(0, 0, 0, 0.05);
}

.dropdownItemStyle {
  padding: 8px 20px;
  cursor: pointer;
}

.selectedItemStyle {
  background-color: rgba($primary, 0.1);
  color: $primary;
  border-left: 3px solid $primary;
}

.hoveredItemStyle {
  background-color: rgba($primary, 0.1);
}

.defaultTextStyle {
  opacity: 0.5;
  font-size: 14px;
}

.selectedTextStyle {
  opacity: 1;
}

.textSelected {
  color: $primary;
}

.marginBottom7 {
  margin-bottom: 7px;
}

.label {
  margin-bottom: 5px;
  display: inline-block;
}

.errorMessage {
  color: $primary;
  font-size: 14px;
  display: block;
}

li {
  list-style: none;
}

.dropOptions {
  padding: 2px;
  margin: 0px;
}

.dropButton a {
  border-top: 1px solid #e2e8f0;
}

.addCategoryButton {
  display: block;
  width: inherit;
  padding: 8px 20px;
  background-color: $super-clear-gray;
  color: $neutral;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: rgba($primary, 0.1);
  }
}
