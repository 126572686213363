.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.contentContainer {
  width: 70%;
  padding: 4vw 0vw;
  max-width: 850px;
  min-width: 270px;
  border-radius: 32px;
  background-color: #fff;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.08),
    0px 5px 8px 0px rgba(0, 0, 0, 0.06),
    0px 1px 14px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 715px) {
    width: 85%;
    border-radius: 24px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.headerContainer {
  margin: 1vw;
}

.info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  min-width: 250px;
  gap: 10px;

  .title {
    display: inherit;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
}

.formContainer {
  width: 100%;
}

.containerButton {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  .button {
    width: 100%;
  }
}

.loaderContainer {
  display: flex;
  align-items: center;
  height: 38px;
}

.image {
  @media (max-width: 915px) {
    display: none;
  }
}
