.table {
  width: 100%;
  border-spacing: 0 16px;
}

tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tbody tr div {
  border-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
