@import '../../../../utils/styles/styles.scss';

.responseBox {
  display: flex;
  flex-direction: column;
  background-color: $neutral;
  border-radius: 15px;
  padding: 16px;
  gap: 16px;
}

.title {
  display: flex;
  align-items: end;
  gap: 8px;
}

.textarea {
  min-height: 120px;
  border: none;
  color: $black;
  outline: none;
  padding: 10px;
  font-size: 14px;
  resize: none;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 2px solid #adadad;
  background-color: rgba($gray, 0.05);
  font-family: 'Space Grotesk', sans-serif;

  &:focus {
    border-bottom: 2px solid $secondary2;
  }
}

.errorBorder {
  border-bottom: 2px solid $primary;
}

.errorText {
  color: $primary;
  font-size: 12px;
  margin-top: 4px;
}

.completedMessage {
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: justify;
}

.response {
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  color: $black;
  font-family: 'Space Grotesk', sans-serif;
}

.responseShowMore {
  font-size: 12.5px;
  font-weight: 600;
  color: $tertiary4;
  font-family: 'Space Grotesk', sans-serif;
}
