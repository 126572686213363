@import '../../../../utils/styles/styles.scss';

.spacing {
  margin: 10px;
}

.container {
  width: 60vh;
  max-width: 60vh;
  padding: 0;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: $super-clear-gray;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $clear-gray;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $gray-text;
  }
}

.header {
  padding: 20px 15px 0 15px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 60vh;
  overflow-y: scroll;
  padding: 5px 15px 20px 15px;
}

.headerContainer {
  margin: 1vw;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 10px;
  padding: 0 20px;
}
