@import '../../../../utils/styles/styles.scss';

.switch {
  display: flex;
  align-items: center;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  width: 55px;
  height: 25.6px;
  background-color: $gray-text;
  border-radius: 34px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 17.6px;
  width: 17.6px;
  left: 4px;
  bottom: 4px;
  background-color: $neutral;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:checked + .slider:before {
  left: auto;
  right: 4px;
}
