@import '../../../../utils/styles/styles.scss';

.formContent {
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.avatar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: bold;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 15px;

  & > div {
    grid-column: span 2;
  }

  & > div:nth-child(odd) {
    grid-column: 1 / span 1;
  }

  & > div:nth-child(even) {
    grid-column: 2 / span 1;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 10px;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  backdrop-filter: blur(1px);
  background-color: rgba($black, 0.05);
  z-index: 1000;
  flex-wrap: wrap;
  align-content: center;
}

.blurred {
  filter: blur(2px);
}
