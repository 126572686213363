@import '../../../../utils/styles/styles.scss';

.addCard {
  display: flex;
  flex-direction: column;
  background-color: $neutral;
  border-radius: 15px;
}

.title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;

  .titleText {
    display: flex;
    flex-direction: column;
  }
}

.formContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;

  .body {
    gap: 5px;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
  }

  .button {
    height: 15px;
  }
}

.textarea {
  min-height: 120px;
  border: none;
  color: $black;
  outline: none;
  padding: 10px;
  font-size: 14px;
  resize: none;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 2px solid #adadad;
  background-color: rgba($gray, 0.05);
  font-family: 'Space Grotesk', sans-serif;

  &:focus {
    border-bottom: 2px solid $secondary2;
  }
}

.errorBorder {
  border-bottom: 2px solid $primary;
}

.errorText {
  color: $primary;
  font-size: 12px;
  margin-top: 4px;
}

.input {
  min-height: 65px;
}
