@import '../../../../utils/styles/styles.scss';

.container {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 5px;
  }

  .boxContainer {
    overflow: hidden;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: row;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }

    .p4 {
      padding: 4px;
    }

    .inputLabel {
      background-color: #b4282b;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      border: 2px solid #b4282b;
      height: 100%;
      gap: 5px;
    }

    .fileLabel {
      flex: 8 1;
      padding: 8px;
      border-radius: 0 8px 8px 0;
      border: 2px solid #b4282b;
    }
  }
}
