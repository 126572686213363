@import '../../../../utils/styles/styles.scss';

tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: auto;
}

.cell {
  background-color: $neutral;
  padding: 10px;
  vertical-align: middle;
}

.image {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
