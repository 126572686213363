@import '../../../../utils/styles/styles.scss';

.container {
  max-height: 20px;
  max-width: 100%;
  background-color: $neutral;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 8px;
  margin: 8px 0px;
}

.input {
  width: 100%;
  height: 100%;
  flex: 1;
  font-size: $b2-medium-size;
  font-weight: $b2-medium-weight;
  font-family: $b2-medium-font-family;
  color: $secondary2;
  border: none;
  outline: none;
  background: transparent;

  &::placeholder {
    color: $tertiary3;
  }
}

.icon {
  color: $tertiary3;
}

.iconContainer {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearButton {
  background: none;
  border: none;
  cursor: pointer;
}
