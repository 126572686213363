.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContainer {
  margin-top: 10px;
  width: 100%;
}

.containerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  min-height: 44px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  height: 50px;
}

.forgotPassword {
  margin-top: 10px;
  margin-right: 20px;
  text-decoration: none;
  align-self: flex-end;
}

.errorMessage {
  text-align: center;
}
