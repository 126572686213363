$primary: #b4282b;
$secondary1: #d93744;
$secondary2: #27262b;
$tertiary1: #ff8982;
$tertiary2: #ef949b;
$tertiary3: #8f8b9f;
$tertiary4: #3b0909;
$tertiary5: #733330;
$neutral: #ffffff;
$gray: #6b779a;
$gray-text: #9796a1;
$black: #191b23;
$clear-gray: #e8e9ea;
$super-clear-gray: #f8f8fa;

$ph1-bold-size: 68.5px;
$ph1-bold-weight: 600;
$ph1-bold-font-family: 'Space Grotesk', sans-serif;

$ph2-bold-size: 47px;
$ph2-bold-weight: 600;
$ph2-bold-font-family: 'Space Grotesk', sans-serif;

$ph2-medium-size: 47px;
$ph2-medium-weight: 500;
$ph2-medium-font-family: 'Space Grotesk', sans-serif;

$ph2-regular-size: 47px;
$ph2-regular-weight: 500;
$ph2-regular-font-family: 'Space Grotesk', sans-serif;

$ph3-bold-size: 38px;
$ph3-bold-weight: 600;
$ph3-bold-font-family: 'Space Grotesk', sans-serif;

$psh3-bold-size: 27px;
$psh3-bold-weight: 600;
$psh3-bold-font-family: 'Space Grotesk', sans-serif;

$psh3-medium-size: 27px;
$psh3-medium-weight: 500;
$psh3-medium-font-family: 'Space Grotesk', sans-serif;

$psh3-regular-size: 27px;
$psh3-regular-weight: 500;
$psh3-regular-font-family: 'Space Grotesk', sans-serif;

$h2-bold-size: 30px;
$h2-bold-weight: 600;
$h2-bold-font-family: 'Space Grotesk', sans-serif;

$h2-medium-size: 30px;
$h2-medium-weight: 500;
$h2-medium-font-family: 'Space Grotesk', sans-serif;

$h2-regular-size: 30px;
$h2-regular-weight: 500;
$h2-regular-font-family: 'Space Grotesk', sans-serif;

$h2-light-size: 30px;
$h2-light-weight: 300;
$h2-light-font-family: 'Space Grotesk', sans-serif;

$sh1-bold-size: 23px;
$sh1-bold-weight: 600;
$sh1-bold-font-family: 'Space Grotesk', sans-serif;

$sh1-regular-size: 23px;
$sh1-regular-weight: 500;
$sh1-regular-font-family: 'Space Grotesk', sans-serif;

$sh2-bold-size: 19px;
$sh2-bold-weight: 600;
$sh2-bold-font-family: 'Space Grotesk', sans-serif;

$sh2-regular-size: 19px;
$sh2-regular-weight: 500;
$sh2-regular-font-family: 'Space Grotesk', sans-serif;

$b1-bold-size: 15px;
$b1-bold-weight: 600;
$b1-bold-font-family: 'Space Grotesk', sans-serif;

$b1-medium-size: 15px;
$b1-medium-weight: 500;
$b1-medium-font-family: 'Space Grotesk', sans-serif;

$b1-regular-size: 15px;
$b1-regular-weight: 400;
$b1-regular-font-family: 'Space Grotesk', sans-serif;

$b2-bold-size: 13px;
$b2-bold-weight: 600;
$b2-bold-font-family: 'Space Grotesk', sans-serif;

$b2-medium-size: 13px;
$b2-medium-weight: 500;
$b2-medium-font-family: 'Space Grotesk', sans-serif;

$b2-regular-size: 13px;
$b2-regular-weight: 400;
$b2-regular-font-family: 'Space Grotesk', sans-serif;

$b3-bold-size: 11.5px;
$b3-bold-weight: 600;
$b3-bold-font-family: 'Space Grotesk', sans-serif;

$b3-medium-size: 11.5px;
$b3-medium-weight: 500;
$b3-medium-font-family: 'Space Grotesk', sans-serif;

$b3-regular-size: 11.5px;
$b3-regular-weight: 400;
$b3-regular-font-family: 'Space Grotesk', sans-serif;
