@import '../../../../utils/styles/styles.scss';

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  gap: 15px;
  min-height: 300px;
}

.buttons {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  max-width: 250px;
  text-align: center;
}

.message {
  max-width: 290px;
  text-align: center;
}

.popupLoadingContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  min-height: 300px;
  flex-wrap: wrap;
  align-content: center;
}
