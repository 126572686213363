.headerCell {
  padding: 12px;
  text-align: left;
  cursor: pointer;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
