@import '../../../../utils/styles/styles.scss';

.loaderContainer {
  border-radius: 50%;
  margin: auto;
  margin-top: 10px;
  padding: 5px;
}
.background {
  background-color: $neutral;
}
