@import '../../../utils/styles/styles.scss';

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
}

.image {
  @media (max-width: 915px) {
    display: none;
  }
}
