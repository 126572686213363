@import '../../../../utils/styles/styles.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 87px;
}

.differentContainer {
  min-height: 83px;
  gap: 0px;
}

.label {
  margin-bottom: 5px;
}

.iconContainer {
  cursor: pointer;
}

.inputContainer {
  display: flex;
  align-items: center;
  background-color: $neutral;
  border: 1px solid rgba($tertiary3, 0.3);
  border-radius: 12px;
  padding: 10px;
  height: 3vh;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1px;

  &.errorForm {
    border-color: $primary;
  }
}

.differentInputContainer {
  display: flex;
  align-items: center;
  background-color: rgba($gray, 0.05);
  border-radius: 12px 12px 0px 0px;
  padding: 10px;
  height: 2.5vh;
  flex-direction: row;
  gap: 10px;
  border: none;
  border-bottom: 2px solid #adadad;
  margin-bottom: 1px;

  &.errorForm {
    border-color: $primary;
  }

  &:focus-within {
    border-color: $secondary2;
  }
}

.input {
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: 'Space Grotesk', sans-serif;
}

.differentInput {
  background-color: transparent;
  font-size: 14px;
  color: $black;
}

.input::placeholder {
  font-family: 'Space Grotesk', sans-serif;
}

.disabled {
  background-color: rgba($gray, 0.2);
}

.errorText {
  color: $primary;
  margin-top: 5px;
}
