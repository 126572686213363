@import '../../../../utils/styles/styles.scss';

.container {
  max-height: 20px;
  overflow: hidden;
  background: linear-gradient(97.21deg, #f94949 -15.06%, $primary 135.29%);
  box-shadow: 0px 8px 16px rgba($secondary2, 0.2);
  border-radius: 8px;
  padding: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &.cancelButton {
    background: $primary;
    box-shadow: none;
    width: fit-content;
    cursor: pointer;
  }

  &.closeButton {
    background: transparent;
    color: black;
    border: 1px solid #6c6c6c;
    box-shadow: none;
    width: fit-content;
    min-width: 80px;
    max-height: 8px;
    cursor: pointer;
  }

  &.changeButton {
    background: $primary;
    color: $neutral;
    box-shadow: none;
    width: fit-content;
    min-width: 80px;
    max-height: 8px;
    cursor: pointer;
  }
  &.disabledButton {
    background: $tertiary3;
    box-shadow: none;
    width: fit-content;
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.loading {
    background: none;
  }
}

.defaultContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.popupContainer {
  width: 100%;
  align-items: center;
  border-radius: 28px;
}

.textContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  cursor: not-allowed;
}

.content {
  display: flex;
  align-items: center;
  gap: inherit;
  justify-content: center;
}

.hidden {
  visibility: hidden;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
