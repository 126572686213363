@import '../../../../utils/styles/styles.scss';

.card {
  position: relative;
  width: 22vw;
  height: 20vh;
  min-height: 145px;
  min-width: 195px;
  max-width: 350px;
  max-height: 205px;
  background-color: $neutral;
  border-radius: 31px;
  padding: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $primary;
    border-radius: inherit;
    mask-image: url('../../../../assets/images/card-lines.svg');
    mask-size: cover;
    -webkit-mask-image: url('../../../../assets/images/card-lines.svg');
    -webkit-mask-size: cover;
    z-index: 1;
  }
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
}

.name {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: space-between;

  .footerItem {
    display: flex;
    flex-direction: column;
  }
}

.input {
  margin-bottom: -9px;
}

.customDropMenuPosition {
  position: absolute;
  top: inherit;
  right: inherit;
  margin-top: 30px;
  margin-left: 10vw;
  min-width: 175px;
}
