.container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: 205px;
  background-color: #f5f7fa;
  transition: margin-left 0.3s ease;

  &.closed {
    margin-left: 45px;
  }
}
