@import '../../../../utils/styles/styles.scss';

.newsDetails {
  background-color: $neutral;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .description {
    text-align: justify;
  }
}

.imageContainer {
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 15px;

  .title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}

.image {
  position: relative;
  width: 68px;
  height: 68px;
  overflow: hidden;
  border-radius: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0) 100%
    );
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.newsTextHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
