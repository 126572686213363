html,
body {
  margin: 0;
  padding: 0;
}

.title {
  text-align: center;
}

.container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: 205px;
  background-color: #f5f7fa;
  transition: margin-left 0.3s ease;

  &.closed {
    margin-left: 45px;
  }
}

.content {
  padding: 2vw;
}

.topContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button {
  flex: 0 1 auto;
  max-width: 200px;
  min-width: 200px;
}

.searchInput {
  flex: 0.5 1 auto;
}
