html,
body {
  margin: 0;
  padding: 0;
}

.title {
  text-align: center;
}

.container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: 205px;
  background-color: #f5f7fa;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
