@import '../../../../utils/styles/styles.scss';

.filterContainer {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.filtersContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  min-height: 32.33px;
  flex-wrap: wrap;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;
}

.newFilter {
  display: flex;
}
.addButtonContainer {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 32.33px;
}

.dropMenu {
  position: absolute;
  top: inherit;
  right: inherit;
  margin-top: 30px;
  margin-left: -35px;
  min-width: 135px;
  z-index: 1001;
}

.filterInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.dropdownContainer {
  min-height: 0px;
}

.dropdown {
  max-height: 10px;
  min-width: 105px;
}

.input {
  min-height: 0px;
  max-width: 100px;
}

.inputStyle {
  background-color: rgba($gray, 0.05);
  border: none;
  max-height: 10px;
}

.icon {
  cursor: pointer;
}

.filterTag {
  display: flex;
  align-items: center;
  background-color: rgba(#733330, 0.3);
  border-radius: 30px;
  padding: 0px 8px;
}

.torre {
  background-color: rgba(#733330, 0.3);
}

.apartamento {
  background-color: $tertiary2;
  color: $neutral;
}

.todos {
  background-color: $gray-text;
  color: $neutral;
}
