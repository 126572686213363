@import '../../../../utils/styles/styles.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(1px);
  background-color: rgba($black, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border: none;

  .modal:focus {
    outline: 0;
  }
}

.modal {
  border: none;
  background-color: $neutral;
  border-radius: 12px;
  padding: 20px 15px;
  min-width: 310px;
  max-width: 800px;
  box-shadow: 6px 6px 15px rgba($black, 0.15);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  text-align: left;
}

.title {
  text-align: left;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
}
