@import '../../../../utils/styles/styles.scss';

.card {
  background-color: $neutral;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s;

  cursor: pointer;

  &.selected {
    box-shadow: 0px 0px 5px 1px rgba(224, 0, 0, 0.5);
    background-color: $neutral !important;
  }
  &:hover {
    background-color: $clear-gray;
  }
}

.icon {
  background-color: #f2f4f9;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0.5px rgba(0, 0, 0, 0.15);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 2;
}
