.imageContainer {
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 15px;

  .title {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .image {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    overflow: hidden;

    .imgContainer {
      height: 80px;
      width: 80px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          ellipse at center,
          rgba(255, 255, 255, 0) 50%,
          rgba(0, 0, 0) 100%
        );
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:hover > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
