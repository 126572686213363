@import '../../../utils/styles/styles.scss';

.container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 83.8vh;
  width: 100%;
  transition: height 0.3s ease;
}

.createMode {
  height: 94vh;
}

.leftSection {
  flex: 1.5;
  display: flex;
  flex-direction: column;
}

.rightSection {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.topContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.searchContainer {
  flex: 0.5;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  flex: 0.3;
}

.newsList {
  overflow: auto;
  flex-grow: 1;
}

.newsCard {
  background-color: $neutral;
  width: 100%;
  padding: 20px 25px;
  border-radius: 15px;
  display: flex;
}

.newsCardContent {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.newsCardInfo {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 3px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

.newsItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.noNewsSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $neutral;
  border-radius: 15px;
  overflow: auto;
}

.newsList {
  padding: 0px;
  margin: 0px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 480px;
}
