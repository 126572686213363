@import '../../../../utils/styles/styles.scss';

.sidebar {
  width: 205px;
  height: 100vh;
  background-color: $neutral;
  border-right: 1px solid #e0e0e0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;

  &.closed {
    width: 45px;
  }
}

.toggleButton {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  top: 22px;

  &:focus {
    outline: none;
  }

  .closeIcon {
    position: absolute;
    left: 7px;
  }
}

.logo {
  padding: 20px;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
