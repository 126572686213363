.topContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
}

.filterContainer {
  flex: 0.05 1 auto;
  display: flex;
  width: 290px;
}

.searchContainer {
  flex: 0.5 1 auto;
}

.tableContainer {
  overflow: auto;
}
