@import '../../../../utils/styles/styles.scss';

.container {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 20px;
  background-color: $neutral;
  box-shadow: 0 4px 8px rgba($black, 0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
  padding-top: 7px;
  padding-bottom: 7px;
  min-width: 175px;
}

.open {
  display: flex;
  gap: 2px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 6px;
  gap: 8px;

  &:hover {
    background-color: rgba($primary, 0.2);
    color: $primary;
  }
}
