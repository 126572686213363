@import '../../../../utils/styles/styles.scss';

.filter {
  padding: 15px;
  position: relative;
  width: 145px;
  display: flex;
  justify-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  cursor: pointer;
}

.active {
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 145px;
    height: 3px;
    background-color: $primary;
    border-radius: 5px 5px 0 0;
  }
}
