@import '../../../../utils/styles/styles.scss';

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: $secondary2;
  transition: background-color 0.3s;
  gap: 10px;
  position: relative;
  border-radius: 10px;
  min-width: 164px;
  height: 22px;

  &:hover {
    background-color: rgba($secondary1, 0.1);
    border-radius: 10px;
  }

  &.active {
    color: $primary;

    i {
      color: $primary;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4.5px;
      background-color: $primary;
      border-radius: 50px 30px 30px 50px;
    }
  }

  &.hasSubItems {
    background-color: transparent;

    &.active {
      background-color: transparent;
    }
  }

  &.noSubItems {
    background-color: transparent;

    &.active {
      background-color: rgba($secondary1, 0.1);

      &:hover {
        background-color: rgba($secondary1, 0.1);
      }
    }
  }
}

.dropdownIcon {
  margin-left: auto;
  transition: transform 0.3s;

  &.open {
    transform: rotate(180deg);
  }
}

.subMenu {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 20px;
}

.subMenuItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  gap: 10px;
  position: relative;
  border-radius: 10px;
  color: $secondary2;
  max-width: 164px;

  &:hover {
    background-color: rgba($secondary1, 0.1);
    border-radius: 10px;
  }

  &.subActive {
    color: $primary;
    background-color: rgba($secondary1, 0.1);
  }
}
