@import '../../../../utils/styles/styles.scss';

.text {
  &--primary {
    color: $primary;
  }
  &--secondary1 {
    color: $secondary1;
  }
  &--secondary2 {
    color: $secondary2;
  }
  &--tertiary1 {
    color: $tertiary1;
  }
  &--tertiary2 {
    color: $tertiary2;
  }
  &--tertiary3 {
    color: $tertiary3;
  }
  &--tertiary4 {
    color: $tertiary4;
  }
  &--tertiary5 {
    color: $tertiary5;
  }
  &--neutral {
    color: $neutral;
  }
  &--gray {
    color: $gray;
  }
  &--gray-text {
    color: $gray-text;
  }
  &--black {
    color: $black;
  }
  &--clear-gray {
    color: $clear-gray;
  }
  &--super-clear-gray {
    color: $super-clear-gray;
  }

  &--ph1-bold {
    font-size: $ph1-bold-size;
    font-weight: $ph1-bold-weight;
    font-family: $ph1-bold-font-family;
  }
  &--ph2-bold {
    font-size: $ph2-bold-size;
    font-weight: $ph2-bold-weight;
    font-family: $ph2-bold-font-family;
  }
  &--ph2-medium {
    font-size: $ph2-medium-size;
    font-weight: $ph2-medium-weight;
    font-family: $ph2-medium-font-family;
  }
  &--ph2-regular {
    font-size: $ph2-regular-size;
    font-weight: $ph2-regular-weight;
    font-family: $ph2-regular-font-family;
  }
  &--ph3-bold {
    font-size: $ph3-bold-size;
    font-weight: $ph3-bold-weight;
    font-family: $ph3-bold-font-family;
  }
  &--psh3-bold {
    font-size: $psh3-bold-size;
    font-weight: $psh3-bold-weight;
    font-family: $psh3-bold-font-family;
  }
  &--psh3-medium {
    font-size: $psh3-medium-size;
    font-weight: $psh3-medium-weight;
    font-family: $psh3-medium-font-family;
  }
  &--psh3-regular {
    font-size: $psh3-regular-size;
    font-weight: $psh3-regular-weight;
    font-family: $psh3-regular-font-family;
  }
  &--h2-bold {
    font-size: $h2-bold-size;
    font-weight: $h2-bold-weight;
    font-family: $h2-bold-font-family;
  }
  &--h2-medium {
    font-size: $h2-medium-size;
    font-weight: $h2-medium-weight;
    font-family: $h2-medium-font-family;
  }
  &--h2-regular {
    font-size: $h2-regular-size;
    font-weight: $h2-regular-weight;
    font-family: $h2-regular-font-family;
  }
  &--h2-light {
    font-size: $h2-light-size;
    font-weight: $h2-light-weight;
    font-family: $h2-light-font-family;
  }

  &--sh1-bold {
    font-size: $sh1-bold-size;
    font-weight: $sh1-bold-weight;
    font-family: $sh1-bold-font-family;
  }
  &--sh1-regular {
    font-size: $sh1-regular-size;
    font-weight: $sh1-regular-weight;
    font-family: $sh1-regular-font-family;
  }

  &--sh2-bold {
    font-size: $sh2-bold-size;
    font-weight: $sh2-bold-weight;
    font-family: $sh2-bold-font-family;
  }
  &--sh2-regular {
    font-size: $sh2-regular-size;
    font-weight: $sh2-regular-weight;
    font-family: $sh2-regular-font-family;
  }

  &--b1-bold {
    font-size: $b1-bold-size;
    font-weight: $b1-bold-weight;
    font-family: $b1-bold-font-family;
  }
  &--b1-medium {
    font-size: $b1-medium-size;
    font-weight: $b1-medium-weight;
    font-family: $b1-medium-font-family;
  }
  &--b1-regular {
    font-size: $b1-regular-size;
    font-weight: $b1-regular-weight;
    font-family: $b1-regular-font-family;
  }

  &--b2-bold {
    font-size: $b2-bold-size;
    font-weight: $b2-bold-weight;
    font-family: $b2-bold-font-family;
  }
  &--b2-medium {
    font-size: $b2-medium-size;
    font-weight: $b2-medium-weight;
    font-family: $b2-medium-font-family;
  }
  &--b2-regular {
    font-size: $b2-regular-size;
    font-weight: $b2-regular-weight;
    font-family: $b2-regular-font-family;
  }

  &--b3-bold {
    font-size: $b3-bold-size;
    font-weight: $b3-bold-weight;
    font-family: $b3-bold-font-family;
  }
  &--b3-medium {
    font-size: $b3-medium-size;
    font-weight: $b3-medium-weight;
    font-family: $b3-medium-font-family;
  }
  &--b3-regular {
    font-size: $b3-regular-size;
    font-weight: $b3-regular-weight;
    font-family: $b3-regular-font-family;
  }
}
