.form {
  padding: 0px 10px;

  .container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
  }
}

.dropdown {
  min-height: 65px;
}
