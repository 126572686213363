.topContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.filterContainer {
  flex: 0.05 1 auto;
  display: flex;
  width: 290px;
}

.searchContainer {
  flex: 0.5 1 auto;
}

.dropContainer {
  width: 280px;
  margin-bottom: -15px;
}

.dropdownContainer {
  min-height: 83px;
}
