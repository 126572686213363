@import '../../../utils/styles/styles.scss';

.topContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.filterContainer {
  flex: 0.2 1 auto;
  display: flex;
  width: 290px;
}

.searchContainer {
  flex: 0.45;
}

.pqrs {
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 73.8vh;

  .tableContainer {
    flex: 1.5;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    max-width: 100%;

    table {
      width: 100%;
      table-layout: fixed;

      tbody tr td:nth-child(3) {
        max-width: 90px;
        white-space: normal;
        word-wrap: break-word;

        span {
          display: -webkit-box;
          max-width: 100%;
          max-height: 4em;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

.detailContainer {
  overflow: auto;
  flex: 1;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.emptyCard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $neutral;
  border-radius: 15px;
}
