@import '../../../../utils/styles/styles.scss';

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  width: 230px;
  margin: 10px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.25);
  margin: -20px;
  border-radius: 12px;
  max-width: 0;
  overflow: hidden;
  opacity: 0;
  transition:
    max-width 0.5s ease-out,
    opacity 0.5s ease-in-out;

  &.active {
    max-width: 100%;
    padding: 30px;
    opacity: 1;
  }
}

.topFormContainer {
  display: flex;
  flex-direction: column;
  width: 230px;
}

.bottomFormContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.parkingLotQuestionContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.parkingLotsListContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 335px;
}

.parkingLotsListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.parkingInput {
  background-color: rgba(107, 119, 154, 0.05);
  max-width: 160px;
}

.squareMeterInput {
  width: 163px;
}

.squareMeterText {
  display: flex;
  align-items: center;
}

.parkingInputContainer {
  width: 180px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
