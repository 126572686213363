@import '../../../../utils/styles/styles.scss';

.card {
  background-color: $neutral;
  border-radius: 15px;
}

.header {
  padding: 30px 30px 22px 30px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;

    .status {
      margin-top: 10px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textRadicada {
  background-color: rgba(239, 148, 155, 0.6);
  color: $neutral;
  padding: 6px 12px;
  border-radius: 15px;
}

.textRevision {
  background-color: rgba(168, 60, 54, 0.4);
  color: $neutral;
  padding: 6px 12px;
  border-radius: 15px;
}

.textCompleted {
  background-color: $gray-text;
  color: $neutral;
  border-radius: 15px;
  padding: 6px 12px;
}

.title {
  font-size: 19px;
  font-weight: 600;
  color: $primary;
  font-family: 'Space Grotesk', sans-serif;
}

.titleShowMore {
  font-size: 14px;
  font-weight: 600;
  color: $tertiary4;
  font-family: 'Space Grotesk', sans-serif;
}

.descShowMore {
  font-size: 12.5px;
  font-weight: 600;
  color: $tertiary4;
  font-family: 'Space Grotesk', sans-serif;
}

.description {
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  color: $black;
  font-family: 'Space Grotesk', sans-serif;
}

.button {
  height: 12px;
}
